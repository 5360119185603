import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import {
  EnableDisableDaytimeSelect,
  AbsoluteRelativeSelect,
  DehumidificationModeSelect,
  EnableDisableSelect
} from '../FormWidgets/Select.js';

import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import Desc from '../FormWidgets/Description';
import { SettingGroupBlock, SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import { LoadSettingsDialog } from '../Utils/load_settings';

import { settingControls, useSavedSettings } from './settingFunctions';

import { SettingActions } from '../components';

export default function ClimateSettings({ controller, parent, ...other }) {
  let { t } = useTranslation();
  let { tab_id } = useParams();

  const [values, setValues] = useState(parent.settings[tab_id]);
  const controls = settingControls(controller, parent, tab_id, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(parent, controls);

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions onSave={controls.saveSettings} onReset={controls.resetSettings} />

      <SettingGroupGrid controller={controller} id={'humidity_sp'}>
        <ShowSetting
          id="humidity_sp_day"
          controller={controller}
          cur_value={values.humidity_sp_day}
          controller_value={parent.settings.climate.humidity_sp_day}
          controls={controls}
          disabled={
            values.vpd_calc_enable === 2 ||
            values.vpd_calc_enable === 1 ||
            values.vpd_calc_enable === -1
          }
        />
        <ShowSetting
          id="humidity_sp_night"
          controller={controller}
          cur_value={values.humidity_sp_night}
          controller_value={parent.settings.climate.humidity_sp_night}
          controls={controls}
          disabled={values.vpd_calc_enable === 1 || values.vpd_calc_enable === -1}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'temp_sp'}>
        <ShowSetting
          id="temp_sp_day"
          controller={controller}
          cur_value={values.temp_sp_day}
          controller_value={parent.settings.climate.temp_sp_day}
          cat={tab_id}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="temp_sp_night"
          controller={controller}
          cur_value={values.temp_sp_night}
          controller_value={parent.settings.climate.temp_sp_night}
          controls={controls}
          type="float"
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'vapour_pressure_deficit_vpd'}>
        <ShowSetting
          id="vpd_sp"
          controller={controller}
          cur_value={values.vpd_sp}
          controller_value={parent.settings.climate.vpd_sp}
          unit={controller.type !== 6000 && parent.settings.system.vpd_format === 0 ? 'hPa' : 'kPa'}
          controls={controls}
          num_digits={controller.type !== 6000 && parent.settings.system.vpd_format === 0 ? 1 : 2}
          type="float"
        />
        <ShowSetting
          id="vpd_sp_night"
          controller={controller}
          cur_value={values.vpd_sp_night}
          controller_value={parent.settings.climate.vpd_sp_night}
          unit={controller.type !== 6000 && parent.settings.system.vpd_format === 0 ? 'hPa' : 'kPa'}
          controls={controls}
          num_digits={controller.type !== 6000 && parent.settings.system.vpd_format === 0 ? 1 : 2}
          type="float"
        />
        <ValueBox controller={controller} id={'climate_vpd_calc_enable'}>
          <Typography gutterBottom>
            <Desc id="vpd_calc_enable" name="VPD Calculation Enable" />
          </Typography>
          <EnableDisableDaytimeSelect
            value={values.vpd_calc_enable}
            name="vpd_calc_enable"
            onChange={(event, value) => controls.setValue('vpd_calc_enable', event.target.value)}
          />
        </ValueBox>
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'preheat_night_day'}>
        <ShowSetting
          id="preheat_night_day"
          controller={controller}
          cur_value={values.preheat_night_day}
          controller_value={parent.settings.climate.preheat_night_day}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'temp_sp_heater'}>
        <ShowSetting
          id="temp_sp_heater_day"
          controller={controller}
          cur_value={values.temp_sp_heater_day}
          controller_value={parent.settings.climate.temp_sp_heater_day}
          max={values.temp_sp_day / 10 - 1}
          controls={controls}
          type="float"
          off_at={49}
        />
        <ShowSetting
          id="temp_sp_heater_night"
          controller={controller}
          cur_value={values.temp_sp_heater_night}
          controller_value={parent.settings.climate.temp_sp_heater_night}
          max={values.temp_sp_night / 10 - 1}
          controls={controls}
          type="float"
        />
      </SettingGroupGrid>

      <SettingGroupBlock label={t('temperature_setpoint_heating_mat')}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <ValueBox controller={controller} id={'climate_toggle_temp_heating_mat_day'}>
            <Typography gutterBottom>
              <Desc id="toggle_temp_heating_mat_day" />
            </Typography>
            <AbsoluteRelativeSelect
              value={values.toggle_temp_heating_mat_day}
              name="toggle_temp_heating_mat_day"
              onChange={(event, value) =>
                controls.setValue('toggle_temp_heating_mat_day', event.target.value)
              }
            />
          </ValueBox>
          <ValueBox controller={controller} id={'climate_toggle_temp_heating_mat_night'}>
            <Typography gutterBottom>
              <Desc id="toggle_temp_heating_mat_night" />
            </Typography>
            <AbsoluteRelativeSelect
              value={values.toggle_temp_heating_mat_night}
              name="toggle_temp_heating_mat_night"
              onChange={(event, value) =>
                controls.setValue('toggle_temp_heating_mat_night', event.target.value)
              }
            />
          </ValueBox>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'stretch' }}>
          {values.toggle_temp_heating_mat_day === 1 ? (
            <ShowSetting
              id="temp_sp_heating_mat_day_abs"
              controller={controller}
              cur_value={values.temp_sp_heating_mat_day_abs}
              controller_value={parent.settings.climate.temp_sp_heating_mat_day_abs}
              controls={controls}
              type="float"
            />
          ) : (
            <ShowSetting
              id="temp_sp_heating_mat_day_rel"
              controller={controller}
              cur_value={values.temp_sp_heating_mat_day_rel}
              controller_value={parent.settings.climate.temp_sp_heating_mat_day_rel}
              controls={controls}
              type="float"
            />
          )}
          {values.toggle_temp_heating_mat_night === 1 ? (
            <ShowSetting
              id="temp_sp_heating_mat_night_abs"
              controller={controller}
              cur_value={values.temp_sp_heating_mat_night_abs}
              controller_value={parent.settings.climate.temp_sp_heating_mat_night_abs}
              controls={controls}
              type="float"
            />
          ) : (
            <ShowSetting
              id="temp_sp_heating_mat_night_rel"
              controller={controller}
              cur_value={values.temp_sp_heating_mat_night_rel}
              controller_value={parent.settings.climate.temp_sp_heating_mat_night_rel}
              controls={controls}
              type="float"
            />
          )}
        </Box>
      </SettingGroupBlock>

      <SettingGroupGrid controller={controller} id={'dehumidification_hl'}>
        <ValueBox controller={controller} id={'climate_dehumidification_mode'}>
          <Typography gutterBottom>
            <Desc id="dehumidification_mode" name="Select Dehumidification Mode" />
          </Typography>
          <DehumidificationModeSelect
            value={values.dehumidification_mode}
            name="dehumidification_mode"
            onChange={(event, value) =>
              controls.setValue('dehumidification_mode', event.target.value)
            }
          />
        </ValueBox>
        <ValueBox controller={controller} id={'climate_heating_to_dehumidify_en'}>
          <Typography gutterBottom>
            <Desc id="heating_to_dehumidify_en" />
          </Typography>
          <EnableDisableSelect
            value={values.heating_to_dehumidify_en}
            name="heating_to_dehumidify_en"
            onChange={(event, value) =>
              controls.setValue('heating_to_dehumidify_en', event.target.value)
            }
          />
        </ValueBox>
      </SettingGroupGrid>

      <SettingActions onSave={controls.saveSettings} onReset={controls.resetSettings} />
    </React.Fragment>
  );
}
