import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import MuiSwitch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { SettingActions } from '../components';
import { firmware_version_infos, statusColors } from '../Constants/constants';
import Desc from '../FormWidgets/Description';
import { NormalValue } from '../FormWidgets/InfoValues';
import {
  AverageMinMaxSelect,
  DateFormatSelect,
  DisplayTimeoutSelect,
  EcAcSelect,
  EnableDisableSelect,
  FirmwareSelect,
  LanguageSelect,
  TempFormatSelect,
  VPDFormatSelect
} from '../FormWidgets/Select';
import { SettingGroup, SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import { useAddress } from '../Hooks/addressHook';
import { useAuth } from '../Hooks/authHook';
import { useIsMobile } from '../Hooks/useMedia';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { HandleAction } from '../Utils/utils.js';
import { settingControls, useSavedSettings } from './settingFunctions';

import getStateManager from '../StateManager.js';

export default function SystemSettings({ controller, ...other }) {
  const isMobile = useIsMobile();
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  const tab_id = 'system';

  const stateManager = getStateManager();
  //const controller = stateManager.getControllerById (id);
  const [values, setValues] = useState(controller.settings[tab_id]);
  const controls = settingControls(controller, controller, tab_id, values, setValues);

  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(
    controller,
    controls
  );

  const refreshFSStatus = () => {
    stateManager.wsManager.sendWSMsg(controller, 'get_fs_status');
  };

  // firmware updates.
  const address = useAddress();
  const auth = useAuth();
  const [firmware_versions, setFirmwareVersions] = React.useState({
    5053: [],
    5052: [],
    5055: [],
    5155: [],
    6000: []
  });
  const [firmware_version, setFirmwareVersion] = React.useState(-1);
  useEffect(() => {
    if (auth.user) {
      refreshFSStatus();
      fetch(address.api_url('/api/versions')).then((response) => {
        if (response.ok) {
          response.text().then((text) => {
            const response = JSON.parse(text);
            setFirmwareVersions({ ...response['version_numbers'] });
          });

          const prevFirmwareValues = JSON.parse(localStorage.getItem('prevFirmwareValues')) || {
            fw_version_number: '',
            esp32_firmware_name: ''
          };

          const prevFwVersionNumber = prevFirmwareValues.fw_version_number;
          const prevWifiFirmwareName = prevFirmwareValues.esp32_firmware_name;

          if (prevFwVersionNumber !== '' && prevWifiFirmwareName !== '') {
            if (
              values.fw_version_number !== prevFwVersionNumber &&
              values.esp32_firmware_name === prevWifiFirmwareName
            ) {
              enqueueSnackbar(t('successful_gc_firmware_update'), { variant: 'success' });
            } else if (
              values.fw_version_number === prevFwVersionNumber &&
              values.esp32_firmware_name !== prevWifiFirmwareName
            ) {
              enqueueSnackbar(t('successful_wifi_firmware_update'), { variant: 'success' });
            } else if (
              values.fw_version_number !== prevFwVersionNumber &&
              values.esp32_firmware_name !== prevWifiFirmwareName
            ) {
              enqueueSnackbar(t('successful_firmware_update'), { variant: 'success' });
            }
          }

          localStorage.setItem(
            'prevFirmwareValues',
            JSON.stringify({
              fw_version_number: values.fw_version_number,
              esp32_firmware_name: values.esp32_firmware_name
            })
          );
        }
      });
    }
  }, [controller.connection.status]);

  const callAction = (type, value) => {
    if (controller.connection.status !== 'bad')
      HandleAction(stateManager.wsManager, controller, type, value);
  };

  const callFirmwareAction = (enqueueSnackbar, update_gc_firmware, update_wifi_firmware) => {
    if ((controller?.type ?? 5053) <= 5155) {
      const firmware_data = firmware_versions[controller?.type][firmware_version];
      if (firmware_data && controller.connection.status !== 'bad') {
        if (update_gc_firmware === 1 && update_wifi_firmware === 1) {
          enqueueSnackbar(t('start_firmware_update'), { variant: 'info' });
          stateManager.wsManager.sendWSMsg(controller, 'do_firmware_update', firmware_data);
        } else if (update_gc_firmware === 1 && update_wifi_firmware === 0) {
          enqueueSnackbar(t('start_gc_firmware_update'), { variant: 'info' });
          stateManager.wsManager.sendWSMsg(controller, 'do_gc_firmware_update', firmware_data);
        } else if (update_gc_firmware === 0 && update_wifi_firmware === 1) {
          enqueueSnackbar(t('start_wifi_firmware_update'), { variant: 'info' });
          stateManager.wsManager.sendWSMsg(controller, 'do_esp32_firmware_update', firmware_data);
        }
      }
    } // industrial
    else {
      const firmware_data = values.firmware_updates[firmware_version];
      if (firmware_data && controller.connection !== 'bad') {
        if (update_gc_firmware === 1 && update_wifi_firmware === 1) {
          stateManager.wsManager.sendWSMsg(controller, 'do_firmware_update', firmware_data, true);
        } else if (update_gc_firmware === 1 && update_wifi_firmware === 0) {
          enqueueSnackbar(t('start_gc_firmware_update'), { variant: 'info' });
          stateManager.wsManager.sendWSMsg(
            controller,
            'do_gc_firmware_update',
            firmware_data,
            true
          );
        } else if (update_gc_firmware === 0 && update_wifi_firmware === 1) {
          enqueueSnackbar(t('start_wifi_firmware_update'), { variant: 'info' });
          stateManager.wsManager.sendWSMsg(
            controller,
            'do_esp32_firmware_update',
            firmware_data,
            true
          );
        }
      }
    }
  };

  // (Auto-) Load saved settings.

  const [open_wifi_dialog, setOpenWifiDialog] = React.useState(false);
  const openWifiDialog = () => {
    setOpenWifiDialog(true);
  };
  const closeWifiDialog = () => {
    setOpenWifiDialog(false);
  };

  const [open_project_dialog, setOpenProjectDialog] = React.useState(false);
  const openProjectDialog = () => {
    setOpenProjectDialog(true);
  };
  const closeProjectDialog = () => {
    setOpenProjectDialog(false);
  };

  const [open_confirm_fw_update_dialog, setOpenConfirmFWUpdateDialog] = React.useState(false);
  const openConfirmFwUpdateDialog = () => {
    setOpenConfirmFWUpdateDialog(true);
  };
  const closeConfirmFwDialogDialog = () => {
    setOpenConfirmFWUpdateDialog(false);
  };

  const [confirm_action_dialog_infos, setOpenConfirmActionDialog] = React.useState({
    open: false,
    type: '',
    value: undefined
  });
  const openConfirmActionDialog = (type, value) => {
    setOpenConfirmActionDialog({ open: true, type: type, value: value });
  };
  const closeConfirmActionDialog = () => {
    setOpenConfirmActionDialog({ open: false, type: '', value: -1 });
  };

  return (
    <React.Fragment>
      <WifiDialog
        open={open_wifi_dialog}
        handleClose={closeWifiDialog}
        stateManager={stateManager}
        controller={controller}
      />
      <ProjectDialog
        open={open_project_dialog}
        handleClose={closeProjectDialog}
        stateManager={stateManager}
        controller={controller}
      />
      <ConfirmActionDialog
        open={confirm_action_dialog_infos.open}
        handleClose={closeConfirmActionDialog}
        controller={controller}
        action_type={confirm_action_dialog_infos.type}
        action_value={confirm_action_dialog_infos.value}
      />

      <ConfirmFirmwareUpdateDialog
        open={open_confirm_fw_update_dialog}
        handleClose={closeConfirmFwDialogDialog}
        handleFirwareUpdate={callFirmwareAction}
      />

      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />

      <SettingGroup controller={controller} id={'formats'}>
        <ValueBox controller={controller} id={'system_temp_format'}>
          <Typography gutterBottom>
            <Desc id="temp_format" name="Temperature Format" />
          </Typography>
          <TempFormatSelect
            value={values.temp_format}
            name="temp_format"
            onChange={(event, value) => controls.setValue('temp_format', event.target.value)}
          />
        </ValueBox>
        <ValueBox controller={controller} id={'system_vpd_format'}>
          <Typography gutterBottom>
            <Desc id="vpd_format" name="VPD Format" />
          </Typography>
          <VPDFormatSelect
            value={values.vpd_format}
            name="vpd_format"
            onChange={(event, value) => controls.setValue('vpd_format', event.target.value)}
          />
        </ValueBox>
        <ValueBox controller={controller} id={'system_date_format'}>
          <Typography gutterBottom>
            <Desc id="date_format" name="Date Format" />
          </Typography>
          <DateFormatSelect
            value={values.date_format}
            name="date_format"
            onChange={(event, value) => controls.setValue('date_format', event.target.value)}
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup controller={controller} id={'controller_menu_settings'}>
        <ValueBox controller={controller} id={'system_language'}>
          <Typography gutterBottom>
            <Desc id="language" name="Language" />
          </Typography>
          <LanguageSelect
            value={values.language}
            name="language"
            onChange={(event, value) => controls.setValue('language', event.target.value)}
          />
        </ValueBox>
        <ValueBox controller={controller} id={'system_display_timeout_gc'}>
          <Typography gutterBottom>
            <Desc id="display_timeout_gc" name="Display Timeout" />
          </Typography>
          <DisplayTimeoutSelect
            value={values.display_timeout}
            name="display_timeout"
            onChange={(event, value) => controls.setValue('display_timeout', event.target.value)}
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup controller={controller} id={'menu_settings'}>
        <ValueBox controller={controller} id={'system_fan_type_select'}>
          <Typography gutterBottom>
            <Desc id="fan_type_select" name="Display Timeout" />
          </Typography>
          <EcAcSelect
            value={values.fan_type_select}
            name="fan_type_select"
            onChange={(event, value) => controls.setValue('fan_type_select', event.target.value)}
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup controller={controller} id={'multi_sensor_handling'}>
        <ValueBox controller={controller} id={'system_multi_sensor_handling_rh'}>
          <Typography gutterBottom>{t('multi_sensor_handling_rh')}</Typography>
          <AverageMinMaxSelect
            value={values.multi_sensor_handling_rh}
            name="multi_sensor_handling_rh"
            onChange={(event, value) =>
              controls.setValue('multi_sensor_handling_rh', event.target.value)
            }
          />
        </ValueBox>
        <ValueBox controller={controller} id={'system_multi_sensor_handling_temp'}>
          <Typography gutterBottom>
            <Desc id="multi_sensor_handling_temp" name="Multi Sensor Handling Temperature" />
          </Typography>
          <AverageMinMaxSelect
            value={values.multi_sensor_handling_temp}
            name="multi_sensor_handling_temp"
            onChange={(event, value) =>
              controls.setValue('multi_sensor_handling_temp', event.target.value)
            }
          />
        </ValueBox>
        <ValueBox controller={controller} id={'system_multi_sensor_handling_co2'}>
          <Typography gutterBottom>
            <Desc id="multi_sensor_handling_co2" name="Multi Sensor Handling CO2" />
          </Typography>
          <AverageMinMaxSelect
            value={values.multi_sensor_handling_co2}
            name="multi_sensor_handling_co2"
            onChange={(event, value) =>
              controls.setValue('multi_sensor_handling_co2', event.target.value)
            }
          />
        </ValueBox>
        <ValueBox controller={controller} id={'system_multi_sensor_handling_pressure'}>
          <Typography gutterBottom>
            <Desc id="multi_sensor_handling_pressure" name="Multi Sensor Handling Pressure" />
          </Typography>
          <AverageMinMaxSelect
            value={values.multi_sensor_handling_pressure}
            name="multi_sensor_handling_pressure"
            onChange={(event, value) =>
              controls.setValue('multi_sensor_handling_pressure', event.target.value)
            }
          />
        </ValueBox>
        <ValueBox controller={controller} id={'system_multi_sensor_handling_heating_mat'}>
          <Typography gutterBottom>
            <Desc id="multi_sensor_handling_heating_mat" name="Multi Sensor Handling Heating Mat" />
          </Typography>
          <AverageMinMaxSelect
            value={values.multi_sensor_handling_heating_mat}
            name="multi_sensor_handling_heating_mat"
            onChange={(event, value) =>
              controls.setValue('multi_sensor_handling_heating_mat', event.target.value)
            }
          />
        </ValueBox>
        <ValueBox controller={controller} id={'system_multi_sensor_handling_leaf'}>
          <Typography gutterBottom>
            <Desc id="multi_sensor_handling_leaf" name="Multi Sensor Handling Leaf" />
          </Typography>
          <AverageMinMaxSelect
            value={values.multi_sensor_handling_leaf}
            name="multi_sensor_handling_leaf"
            onChange={(event, value) =>
              controls.setValue('multi_sensor_handling_leaf', event.target.value)
            }
          />
        </ValueBox>
      </SettingGroup>

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />

      <SettingGroupGrid controller={controller} id={'sensor_calibration_hl'}>
        <ValueBox controller={controller} id={'system_pressure_auto_cal'}>
          <Typography gutterBottom>
            <Desc id="pressure_auto_cal" />
          </Typography>
          <EnableDisableSelect
            value={values.pressure_auto_cal}
            name="pressure_auto_cal"
            onChange={(event, value) => controls.setValue('pressure_auto_cal', event.target.value)}
          />
        </ValueBox>
        <Button
          color="primary"
          onClick={(event) => callAction('action_calibrate_pressure_trigger')}
        >
          {t('calibrate_pressure_trigger')}
        </Button>

        <ShowSetting
          id="filter_val_sensor_co2"
          controller={controller}
          cur_value={values.filter_val_sensor_co2}
          controller_value={controller.settings.system.filter_val_sensor_co2}
          controls={controls}
        />
        <Button
          color="primary"
          onClick={() => callAction('action_transmit_filter_sensor', values.filter_val_sensor_co2)}
        >
          {t('transmit')}
        </Button>
        <ShowSetting
          id="calib_val_sensor_co2"
          controller={controller}
          cur_value={values.calib_val_sensor_co2}
          controller_value={controller.settings.system.calib_val_sensor_co2}
          controls={controls}
        />
        <Button
          color="primary"
          onClick={() =>
            callAction('action_transmit_calib_filter_sensor_co2', values.calib_val_sensor_co2)
          }
        >
          {t('transmit')}
        </Button>
      </SettingGroupGrid>
      <SettingGroupGrid>
        <Button
          onClick={() => openConfirmActionDialog('action_restore_factory_default', undefined)}
          style={{ color: statusColors.bad, border: '1px solid' }}
        >
          {t('restore_factory_default')}
        </Button>
      </SettingGroupGrid>

      <SettingGroup controller={controller} id={'firmware_hl'}>
        <ValueBox controller={controller} id={'system_current_firware_version_main_controller'}>
          <Typography
            gutterBottom
            style={isMobile ? { wordWrap: 'break-word', whiteSpace: 'pre-wrap' } : {}}
          >
            <strong>{t('current_firware_version_main_controller')}:</strong>
          </Typography>
          <label style={{ whiteSpace: 'pre-line' }}>
            <i>{values.fw_version_number}</i>
          </label>
        </ValueBox>
        <ValueBox controller={controller} id={'system_current_firware_version_wifi'}>
          <Typography
            gutterBottom
            style={isMobile ? { wordWrap: 'break-word', whiteSpace: 'pre-wrap' } : {}}
          >
            <strong>{t('current_firware_version_wifi')}:</strong>
          </Typography>
          <label style={{ whiteSpace: 'pre-line' }}>
            <i>{values.esp32_firmware_name}</i>
          </label>
        </ValueBox>
        <ValueBox controller={controller} id={'system_firmware_update'}>
          <Typography gutterBottom>{t('firmware_update')}</Typography>
          {controller?.type <= 5155 && (
            <FirmwareSelect
              value={firmware_version}
              versions={firmware_versions[controller?.type]}
              name="firmware_update"
              id="firmware_update"
              contApiVersion={controller?.version}
              contFWVersion={controller.settings.system.esp32_firmware_name}
              onChange={(event, value) => setFirmwareVersion(event.target.value)}
            />
          )}
          {controller?.type === 6000 && (
            <FirmwareSelect
              value={firmware_version}
              versions={controller.settings.system.firmware_updates}
              name="firmware_update"
              id="firmware_update"
              onChange={(event, value) => setFirmwareVersion(event.target.value)}
            />
          )}
        </ValueBox>
        <div>
          <Button
            color="primary"
            disabled={firmware_version === -1}
            onClick={() => openConfirmFwUpdateDialog()}
          >
            {t('apply_firmware_update')}
          </Button>
          <br />
          {(firmware_version_infos[controller?.type] ?? []).map((entry) => (
            <>
              <a
                href={entry.link}
                style={{ color: statusColors.good }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {entry.text} <PictureAsPdfIcon />
              </a>
              <br />
            </>
          ))}
        </div>
      </SettingGroup>

      <SettingGroup controller={controller} id={'fs_status_hl'}>
        <ValueBox controller={controller} id={'system_total_size'}>
          <Typography gutterBottom>
            <Desc id={'total_size'} />
          </Typography>
          {(controller.fs_status.total_size / (1024 * 1024)).toFixed(1) >= 100.0 ? (
            <NormalValue
              name="total_size"
              value={(controller.fs_status.total_size / (1024 * 1024 * 1024)).toFixed(1)}
              unit="GB"
              style={{ fontSize: '1.3rem' }}
            />
          ) : (
            <NormalValue
              name="total_size"
              value={(controller.fs_status.total_size / (1024 * 1024)).toFixed(1)}
              unit="MB"
              style={{ fontSize: '1.3rem' }}
            />
          )}
        </ValueBox>
        <ValueBox controller={controller} id={'system_free'}>
          <Typography gutterBottom>
            <Desc id={'free'} />
          </Typography>
          <NormalValue
            name="free"
            value={controller.fs_status.free}
            unit="%"
            style={{ fontSize: '1.3rem' }}
          />
        </ValueBox>
        <ValueBox controller={controller} id={'system_flash_status'}>
          <Typography gutterBottom>
            <Desc id={'flash_status'} />
          </Typography>
          <NormalValue
            name="fs_status"
            value={controller.fs_status.flash_status ? 'OK' : 'ERROR'}
            style={{ fontSize: '1.3rem' }}
          />
        </ValueBox>
        <ValueBox controller={controller} id={'system_fs_status'}>
          <Typography gutterBottom>
            <Desc id={'fs_status'} />
          </Typography>
          <NormalValue
            name="fs_status"
            value={controller.fs_status.fs_status ? 'OK' : 'ERROR'}
            style={{ fontSize: '1.3rem' }}
          />
        </ValueBox>
        <div>
          <Button color="primary" onClick={() => refreshFSStatus()}>
            {t('refresh')}
          </Button>
          <br />
          <Button
            color="primary"
            onClick={() => openConfirmActionDialog('action_erase_flash', undefined)}
          >
            {t('erase_flash')}
          </Button>
        </div>
      </SettingGroup>
      <SettingGroup controller={controller} id={'wifi_options'}>
        <Button color="primary" onClick={openWifiDialog}>
          {t('change_wifi_options')}
        </Button>
      </SettingGroup>
      <SettingGroup controller={controller} id={'project_settings'}>
        <Button color="primary" onClick={openProjectDialog}>
          {t('toggle_project_restrictions')}
        </Button>
      </SettingGroup>
    </React.Fragment>
  );
}

function ConfirmActionDialog({ open, handleClose, controller, action_type, action_value }) {
  let { t } = useTranslation();

  const stateManager = getStateManager();

  const doAction = (event) => {
    HandleAction(stateManager.wsManager, controller, action_type, action_value);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('confirm_action_title')}</DialogTitle>
      <DialogContent>{t(action_type + '_text')}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={doAction} color="primary">
          {t('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ConfirmFirmwareUpdateDialog({ open, handleClose, handleFirwareUpdate }) {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();

  const [update_gc_firmware, setUpdateGcFirmware] = React.useState(1);
  const [update_wifi_firmware, setUpdateWifiFirmware] = React.useState(1);

  const doAction = (event) => {
    handleFirwareUpdate(enqueueSnackbar, update_gc_firmware, update_wifi_firmware);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('confirm_firmware_update_title')}</DialogTitle>
      <DialogContent>
        {t('confirm_firmware_update_text')}
        <Typography gutterBottom>
          <MuiSwitch
            checked={update_gc_firmware === 1}
            onClick={(event) => {
              setUpdateGcFirmware(event.target.checked ? 1 : 0);
            }}
            color="primary"
          />
          <Desc id="update_gc_firmware" />
        </Typography>
        <Typography gutterBottom>
          <MuiSwitch
            checked={update_wifi_firmware === 1}
            onClick={(event) => {
              setUpdateWifiFirmware(event.target.checked ? 1 : 0);
            }}
            color="primary"
          />
          <Desc id="update_wifi_firmware" />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={doAction} color="primary">
          {t('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function WifiDialog({ open, handleClose, stateManager, controller }) {
  let { t } = useTranslation();

  const [name, setName] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [msg, setMsg] = React.useState('');

  const changeWifi = (event) => {
    let data = { password: password, name: name };
    if (password === null || name === null)
      setMsg('All fields (password and name) must be filled.');
    else if (data.password.length < 8) setMsg('Password must be at least 8 characters long');
    else if (data.name.length < 3) setMsg('Wifi name must be at least 3 characters long');
    else {
      stateManager.wsManager.sendWSMsg(controller, 'set_ap_credentials', data);
      //setWSReq(CreateControllerWSRequest(wsRef.current, id, 'set_ap_credentials', data));
      handleClose();
      setMsg('');
    }
  };
  const resetWifi = (event) => {
    let data = { password: '', name: '', reset: true };
    stateManager.wsManager.sendWSMsg(controller, 'set_ap_credentials', data);
    //setWSReq(CreateControllerWSRequest(wsRef.current, id, 'set_ap_credentials', data));
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('change_wifi_options')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label={t('label_wifi_name')}
          type="text"
          fullWidth
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label={t('label_wifi_password')}
          type="password"
          fullWidth
          autoComplete="new-password"
          onChange={(event) => setPassword(event.target.value)}
        />
        {msg}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={changeWifi} color="primary">
          {t('apply')}
        </Button>
        <Button title={t('reset_to_factory_default')} onClick={resetWifi} color="primary">
          {t('reset')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ProjectDialog({ open, handleClose, controller }) {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  const [pin, setPin] = React.useState(null);

  const ssid = () => {
    return controller.id + '_removed_project_restrictions';
  };

  const toggleProjectRestrictions = () => {
    fetch('api/confirm_pin');
    fetch(
      'api/confirm_pin?' +
        new URLSearchParams({
          project_id: controller.stable_project_id,
          pin: pin
        }).toString()
    )
      .then((response) => {
        if (response.ok) {
          if (controller.project_id > 0) {
            sessionStorage.setItem(ssid(), true);
            controller.project_id = 0;
            enqueueSnackbar(t('removed_project_restrictions'), { variant: 'info' });
          } else {
            controller.project_id = controller.stable_project_id;
            sessionStorage.removeItem(ssid());
            enqueueSnackbar(t('enabled_project_restrictions'), { variant: 'info' });
          }
        } else {
          response.text().then((text) => enqueueSnackbar(t(text), { variant: 'error' }));
        }
      })
      .catch((_) => enqueueSnackbar(t('unknown_error'), { variant: 'error' }));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {t('confirm_tgl_project_restrictions_title')}
      </DialogTitle>
      <DialogContent>
        {t('confirm_tgl_project_restrictions_text')}
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label={t('label_project_settings_pin')}
          type="password"
          fullWidth
          autoComplete="new-password"
          onChange={(event) => setPin(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={toggleProjectRestrictions} color="primary">
          {t('toggle_project_restrictions')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
