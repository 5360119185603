import momentWithLocale from '../local_moment';

import createModule from '../glue.mjs';

export const wasm = createModule().then((Module) => ({
  fromBinary: Module.cwrap('MsgpackToString', 'string', ['array', 'number']),
  toBinary: Module.cwrap('StringToMsgpack', 'string', ['string'])
}));

export function CreateControllerWSRequest(socket, controller_id, command, data = {}) {
  let req = {};
  req.internal_id = controller_id;
  req.cookie = document.cookie;
  req.data = data;
  req.command = command;
  req.socket = socket;
  return req;
}

export function CreateGlobalWSRequest(socket, command, data = {}) {
  let req = {};
  req.cookie = document.cookie;
  req.internal_id = '###global###';
  req.data = data;
  req.command = command;
  req.socket = socket;
  return req;
}

export function HandleAction(wsManager, controller, type, value) {
  let data = { id: type, value: 0 };
  if (value !== undefined) {
    if (Number.isInteger(Number.parseInt(value, 10))) data['value'] = Number.parseInt(value, 10);
    else return;
  }

  wsManager.sendWSMsg(controller, 'action', data);
}

export function DeepCopyObj(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function compare_alarm_log_entries_by_timestamp(log_entry_a, log_entry_b) {
  if (log_entry_a['ts'] < log_entry_b['ts']) return -1;
  if (log_entry_a['ts'] > log_entry_b['ts']) return 1;
  return 0;
}

function SortAlarmLog(alarm_log) {
  alarm_log.sort(compare_alarm_log_entries_by_timestamp);
  return alarm_log;
}

function RemoveAlarmLogDuplicates(alarm_log) {
  let new_alarm_log = [];
  alarm_log.forEach((log_entry) => {
    const existing = new_alarm_log.filter((x, i) => {
      return x['id'] === log_entry['id'] && x['triggered'] === log_entry['triggered'];
    });
    if (existing.length === 0) new_alarm_log.push(log_entry);
  });
  return new_alarm_log;
}

export function EditAlarmLog(alarm_log) {
  alarm_log = RemoveAlarmLogDuplicates(alarm_log);
  alarm_log = SortAlarmLog(alarm_log);
  // alarm_log = alarm_log.slice(0,10);
  return alarm_log;
}

export function AlarmInList(alarm_id, new_alarms, controller_id) {
  for (let i = 0; i < new_alarms.length; i++) {
    if (controller_id + '_' + new_alarms[i] === alarm_id) {
      return true;
    }
  }
  return false;
}

export function RemoveMessageDuplicates(messages) {
  let new_messages = [];
  messages.forEach((message) => {
    const existing = new_messages.filter((x, i) => {
      return x['id'] === message['id'];
    });
    if (existing.length === 0) new_messages.push(message);
  });
  return new_messages;
}

export function RemoveMessageOfController(messages, controller_name) {
  let new_messages = [];
  messages.forEach((message) => {
    if (message['controller'] !== controller_name) new_messages.push(message);
  });
  return new_messages;
}

export function Ran(min, max, without) {
  while (true) {
    var num = Number.parseInt(Math.random() * (max + 1 - min) + min, 10);
    if (num !== without) return num;
  }
}

export function addZ(value) {
  return value < 10 ? '0' + value : value;
}

export function ParseFloat(number, format) {
  let str = Number.parseFloat(number).toFixed(1);
  return format === '.' ? str : str.replace('.', format);
}

export function TransformHistoryValue(value, type) {
  let r_val;
  if (value === -1 || value === 0xffff) r_val = -1;
  else if (type === 'co2' || type === 'time') r_val = value;
  else r_val = Number.parseFloat(value / 10);
  return r_val;
}

export function UpdateHistory(history, data) {
  // Update history-infos
  history.pending_data.push(data);
  history.progress = data['progress'];
  if (data.time.length > 0) {
    history.last_ts = data.time[data.time.length - 1];
  } else {
    var date = new Date();
    history.last_ts = date.getTime();
  }
  // If all data have arived, sort and then add.
  if (data['progress'] === 100) {
    history.pending_data.sort((a, b) => a.part - b.part);
    ClearHistoryValues(history);
    for (const data of history.pending_data) UpdateHistoryValues(history, data);
    history.pending_data = [];
  }
  return history;
}

function ClearHistoryValues(cur) {
  for (const type of [
    'time',
    'humidity',
    'temp',
    'co2',
    'vpd',
    'temp_leaf_current',
    'vol_water_content',
    'ec_root_zone'
  ])
    cur[type] = [];
}

function UpdateHistoryValues(cur, next) {
  for (const type of [
    'time',
    'humidity',
    'temp',
    'co2',
    'vpd',
    'temp_leaf_current',
    'vol_water_content',
    'ec_root_zone'
  ]) {
    cur[type].push(...next[type].map((val) => TransformHistoryValue(val, type)));
  }
}

export function PrintDate(timestamp, format) {
  if (timestamp !== undefined && timestamp > 10)
    return momentWithLocale(timestamp).utc().format(format);
  return 'n/a';
}

// safely handles circular references
export function dump(obj, indent = 2) {
  let cache = [];
  let str = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  );
  cache = null;
  return str;
}

export function BinaryToUintArray(binary_str, len) {
  var array = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    array[i] = binary_str.charCodeAt(i);
  }
  return array;
}

// https://gist.github.com/manast/1185904
// start:
// `let timer = new Interval (500, () => { ... });`
// `timer.start();`
// stop:
// `timer.stop();`
export function Interval(fn, duration) {
  var _this = this;
  this.baseline = undefined;

  this.run = function () {
    if (_this.baseline === undefined) {
      _this.baseline = new Date().getTime();
    }
    fn();
    var end = new Date().getTime();
    _this.baseline += duration;

    var nextTick = duration - (end - _this.baseline);
    if (nextTick < 0) {
      nextTick = 0;
    }

    _this.timer = setTimeout(function () {
      _this.run(end);
    }, nextTick);
  };

  this.stop = function () {
    clearTimeout(_this.timer);
  };
}

export function setImmediatelyInterval(func, delay) {
  func();
  return setInterval(func, delay);
}

export function makePhase(init) {
  return {
    name_phase_short: init.name_phase_short || '',
    name_phase_full: '',
    phase_id: init.phase_id,
    humidity_sp_day: init.humidity_sp_day || -1,
    humidity_sp_night: init.humidity_sp_night || -1,
    temp_sp_day: init.temp_sp_day || -1,
    temp_sp_night: init.temp_sp_night || -1,
    vpd_sp: init.vpd_sp || -1,
    vpd_sp_night: init.vpd_sp || -1,
    vpd_calc_enable: init.vpd_calc_enable || 0,
    temp_sp_heater_day: init.temp_sp_heater_day || -1,
    temp_sp_heater_night: init.temp_sp_heater_night || -1,
    temp_sp_heating_mat_day_rel: init.temp_sp_heating_mat_day_rel || -1,
    temp_sp_heating_mat_night_rel: init.temp_sp_heating_mat_night_rel || -1,
    temp_sp_heating_mat_day_abs: init.temp_sp_heating_mat_day_abs || -1,
    temp_sp_heating_mat_night_abs: init.temp_sp_heating_mat_night_abs || -1,
    toggle_temp_heating_mat_day: init.toggle_temp_heating_mat_day || 0,
    toggle_temp_heating_mat_night: init.toggle_temp_heating_mat_night || 1,
    co2_control: init.co2_control || 0,
    co2_sp: init.co2_sp || -1,
    temp_sp_co2: init.temp_sp_co2 || -1,
    temp_heater_co2: init.temp_heater_co2 || -1,
    lights_max_ch_1: init.lights_max_ch_1 || -1,
    lights_max_ch_2: init.lights_max_ch_2 || -1,
    lights_max_ch_3: init.lights_max_ch_3 || -1,
    lights_max_ch_4: init.lights_max_ch_4 || -1,
    time_day_start: init.time_day_start || '01:00:00',
    time_day_end: init.time_day_end || '01:00:00',
    time_interval_1_on_day: init.time_interval_1_on_day || '00:00:00',
    time_interval_1_off_day: init.time_interval_1_off_day || '00:00:00',
    time_interval_1_on_night: init.time_interval_1_on_night || '00:00:00',
    time_interval_1_off_night: init.time_interval_1_off_night || '00:00:00',
    time_interval_2_on_day: init.time_interval_2_on_day || '00:00:00',
    time_interval_2_off_day: init.time_interval_2_off_day || '00:00:00',
    time_interval_2_on_night: init.time_interval_2_on_night || '00:00:00',
    time_interval_2_off_night: init.time_interval_2_off_night || '00:00:00',
    time_event1_start: init.time_event1_start || '00:00:00',
    time_event1_duration: init.time_event1_duration || '00:00:00',
    time_event1_exec_gap: init.time_event1_exec_gap || 20,
    time_event2_start: init.time_event2_start || '00:00:00',
    time_event2_duration: init.time_event2_duration || '00:00:00',
    time_event2_exec_gap: init.time_event2_exec_gap || 20,
    time_event3_start: init.time_event3_start || '00:00:00',
    time_event3_duration: init.time_event3_duration || '00:00:00',
    time_event3_exec_gap: init.time_event3_exec_gap || 20,
    time_event4_start: init.time_event4_start || '00:00:00',
    time_event4_duration: init.time_event4_duration || '00:00:00',
    time_event4_exec_gap: init.time_event4_exec_gap || 20,
    time_event5_start: init.time_event5_start || '00:00:00',
    time_event5_duration: init.time_event5_duration || '00:00:00',
    time_event5_exec_gap: init.time_event5_exec_gap || 20,
    time_event6_start: init.time_event6_start || '00:00:00',
    time_event6_duration: init.time_event6_duration || '00:00:00',
    time_event6_exec_gap: init.time_event6_exec_gap || 20,
    time_event7_start: init.time_event7_start || '00:00:00',
    time_event7_duration: init.time_event7_duration || '00:00:00',
    time_event7_exec_gap: init.time_event7_exec_gap || 20,
    time_event8_start: init.time_event8_start || '00:00:00',
    time_event8_duration: init.time_event8_duration || '00:00:00',
    time_event8_exec_gap: init.time_event8_exec_gap || 20
  };
}

export function makeMultiOutObject(controller, parent) {
  if (controller.type >= 6000) {
    let slaves = {};
    for (let s of controller.slaves) {
      for (let mo of Object.values(s.multi_outs ?? {}).filter((mo) => mo.zone === parent.id)) {
        if (!(s.address in slaves)) {
          slaves[s.address] = {};
        }
        slaves[s.address][mo.id] = mo;
      }
    }

    return slaves;
  } else if (controller.type === 5155 && parent.settings.multi_io != null) {
    let slaves = {};
    for (let i = 0; i < parent.settings.multi_io.ios.length ?? 0; i++) {
      let mo = {
        index: i,
        address: parent.settings.multi_io.ios[i].addr_gcl,
        id: parent.settings.multi_io.ios[i].id_per_addr + 1,
        state: parent.multi_io_infos[i].status,
        info: parent.multi_io_infos[i].info,
        info_type: parent.multi_io_infos[i].info_type,
        func: parent.settings.multi_io.ios[i].function,
        type: parent.settings.multi_io.ios[i].hw_type >>> 4,
        zone: parent.settings.multi_io.ios[i].zone,
        memo: parent.settings.multi_out['out_' + (i + 1) + '_memo']
      };
      if (!(mo.address in slaves)) {
        slaves[mo.address] = {};
      }
      slaves[mo.address][mo.id] = mo;
    }
    return slaves;
  } else {
    let multi_outs = [];
    for (let i = 1; i <= parent.settings.multi_out_values.out_count ?? 0; i++) {
      let mo = {
        id: i,
        state: parent.infos['out_' + i + '_state'],
        func: parent.settings.multi_out['out_' + i + '_func'],
        type: parent.settings.multi_out['out_' + i + '_type'],
        memo: parent.settings.multi_out['out_' + i + '_memo']
      };
      multi_outs.push(mo);
    }
    return multi_outs;
  }
}

export function makeSlaveInfoObject(controller, parent) {
  if (controller.type === 5155 && parent.settings.multi_io != null) {
    let slaves_infos = {};
    for (let i = 0; i < parent.infos.slave_infos.length ?? 0; i++) {
      let si = {
        index: i,
        address: parent.infos.slave_infos[i].slave_address,
        status: parent.infos.slave_infos[i].slave_status,
        elapsed_time: parent.infos.slave_infos[i].slave_elapsed_time
      };
      if (!(si.address in slaves_infos)) {
        slaves_infos[si.address] = {};
      }
      slaves_infos[si.address] = si;
    }
    return slaves_infos;
  }
}

export function getEventTimerIds(values) {
  return Object.keys(values).reduce((filtered, key) => {
    let match = key.match(/^event([0-9]+)_cycles/);
    if (match && match.length > 1 && !filtered.includes(Number(match[1]))) {
      filtered.push(Number(match[1]));
    }
    return filtered;
  }, []);
}

export function getIntervalTimerIds(values) {
  return Object.keys(values).reduce((filtered, key) => {
    let match = key.match(new RegExp(`^interval_type_([0-9]+)`));
    if (match && match.length > 1 && !filtered.includes(Number(match[1]))) {
      filtered.push(Number(match[1]));
    }
    return filtered;
  }, []);
}

export function IndexedId(index, field_id) {
  return index + ':' + field_id;
}

export function GetIndexedId(id) {
  const index = Number.parseInt(id.split(':')[0]);
  const field_id = id.split(':')[1];
  return [index, field_id];
}
