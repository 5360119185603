function CleanUp() {
  sessionStorage.removeItem('controllers');
  sessionStorage.removeItem('user');
  document.cookie = 'SESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  localStorage.clear();
}

const Auth = {
  isAuthenticated: false,

  signin(cb, username, password, api_url) {
    let req = username + '\n' + password;

    if ((process.env.REACT_APP_INDUSTRIAL ?? 'false') === 'true') {
      req = JSON.stringify({ username: username, password: password }); // use this for industrial. When DTOs are fully implemented we can remove this
    }

    fetch(api_url, { method: 'POST', body: req }) // Check if this be handled differently for Industrial Lite and Industrial Pro
      .then((response) => {
        if (response.ok) {
          response.text().then((text) => {
            Auth.isAuthenticated = true;
            document.cookie = text;
            cb('good');
          });
        } else if (response.status === 401) {
          cb('Invalid username or password.');
        } else {
          cb('login failed: ' + response.status);
        }
      })
      .catch((error) => cb('login failed: ' + error + ', http: "' + api_url + '"'));
  },

  signout(cb, api_url) {
    Auth.isAuthenticated = false;
    fetch(api_url, { method: 'POST' }).then((_) => {
      CleanUp();
      cb();
    });
    // TODO (fux): handle errors
  },

  register(cb, username, password, password2, email, api_url) {
    let req = { id: username, pw1: password, pw2: password2 };
    if (email !== '') req['email'] = email;
    req['language'] = localStorage.getItem('i18nextLng') || 'de';
    fetch(api_url, { method: 'POST', body: JSON.stringify(req) })
      .then((response) => {
        if (response.ok) {
          response.text().then((text) => {
            Auth.isAuthenticated = true;
            document.cookie = text;
            cb('good');
          });
        } else {
          response
            .text()
            .then((text) => cb('registration failed: ' + response.status + ' ' + text));
        }
      })
      .catch((error) => cb('registration failed: ' + error + ', http: "' + api_url + '"'));
  },

  deleteAccount(cb, api_url) {
    fetch(api_url, { method: 'POST' })
      .then((response) => {
        if (response.ok) {
          CleanUp();
          sessionStorage.clear();
          cb('good');
        } else {
          cb('delete_account_error');
        }
      })
      .catch((error) => cb('Deleting account failed: ' + error));
  }
};

export default Auth;
